import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class NewVersionModelStateHandler {
  loadNewVersionModelScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.projectScreen.selectedProject) {
      this.state.screenState = ScreenState.Projects_NewVersionModel;
      this.state.loading = false;
      this.state.newVersionModelScreen = {
        loadingForm: true,
        formError: "",
        versionName: "",
        description: "",
        phases: [
          {
            key: 0,
            name: "Aan het laden...",
            disabled: true,
          },
        ],
        phase: 0,
      };
      callback(this.state);

      // Get phases
      this.phaseApi
        .apiGrexmanagerPhaseList()
        .then((phases) => {
          this.state.newVersionModelScreen.phases = [
            {
              key: 0,
              name: "-- niet gedefinieerd --",
              disabled: false,
            },
          ].concat(
            phases.map((phase) => {
              return {
                key: phase.id,
                name: phase.description || "",
                disabled: false,
              };
            })
          );
          this.state.newVersionModelScreen.phase = 0;
          this.state.newVersionModelScreen.loadingForm = false;
          callback(this.state);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.newVersionModelScreen.formError =
            "Fases konden niet geladen worden.";
          callback(this.state);
          setTimeout(() => {
            this.state.newVersionModelScreen.formError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  newVersionModelUpdateForm(
    this: AppStateHandler,
    newFormValues: {
      versionName?: string;
      description?: string;
      phase?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.versionName !== undefined) {
      this.state.newVersionModelScreen.versionName = newFormValues.versionName;
    }
    if (newFormValues.description !== undefined) {
      this.state.newVersionModelScreen.description = newFormValues.description;
    }
    if (newFormValues.phase !== undefined) {
      this.state.newVersionModelScreen.phase = newFormValues.phase;
    }
    callback(this.state);
  }

  newVersionModelSave(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.newVersionModelScreen.versionName === "" ||
      this.state.newVersionModelScreen.phase < 0
    ) {
      this.state.newVersionModelScreen.formError =
        "Vul alle velden in die gemarkeerd zijn met een *";
      callback(this.state);
      setTimeout(() => {
        this.state.newVersionModelScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else if (
      this.state.newVersionModelScreen.versionName
        .split("")
        .some((char) => this.state.unallowedChars.includes(char))
    ) {
      this.state.newVersionModelScreen.formError =
        "De naam van de versie mag geen speciale tekens bevatten.";
      callback(this.state);
      setTimeout(() => {
        this.state.newVersionModelScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else {
      this.state.loading = true;
      callback(this.state);

      if (this.state.projectScreen.selectedProject) {
        this.versionModelApi
          .apiGrexmanagerVersionModelCreate({
            versionModelCreateRequest: {
              description:
                this.state.newVersionModelScreen.description === ""
                  ? null
                  : this.state.newVersionModelScreen.description,
              name: this.state.newVersionModelScreen.versionName,
              phase:
                this.state.newVersionModelScreen.phase === 0
                  ? null
                  : this.state.newVersionModelScreen.phase,
              project: this.state.projectScreen.selectedProject,
            },
          })
          .then(() => {
            this.state.loading = false;
            callback(this.state);
            this.changeScreen(ScreenState.Projects, callback);
          })
          .catch((error) => {
            console.log(error);
            let message = "Er is een fout opgetreden tijdens het opslaan.";
            if (error.response.status === 409) {
              message = "Er bestaat al een versie met deze naam.";
            }
            this.state.newVersionModelScreen.formError = message;
            this.state.loading = false;
            console.log(message);
            callback(this.state);
            setTimeout(() => {
              this.state.newVersionModelScreen.formError = "";
              callback(this.state);
            }, 5000);
          });
      }
    }
  }
}
