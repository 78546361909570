import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";

export class VersionModelInfoStateHandler {
  loadVersionModelInfoScreen(
    this: AppStateHandler,
    versionId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.changeScreen(ScreenState.Projects_VersionModelInfo, callback);
    this.state.versionInfoScreen = {
      loadingForm: true,
      formError: "",
      versionId: null,
      versionName: "",
      description: "",
      phases: [
        {
          key: 0,
          name: "Aan het laden...",
          disabled: true,
        },
      ],
      phase: 0,
      author: "",
      createdAt: null,
      lastEdited: null,
      lastModifiedByNameFull: "",
      attachments: null,
      projectRole: null,
      viewerUri: null,
      attachmentToDelete: null,
    };
    this.state.VersionRegionMapScreen.outlineUri = null;
    this.state.FeatureMapScreen.editorUri = null;
    callback(this.state);

    // Get phases
    this.phaseApi
      .apiGrexmanagerPhaseList()
      .then((phases) => {
        this.state.versionInfoScreen.phases = [
          {
            key: 0,
            name: "-- niet gedefinieerd --",
            disabled: false,
          },
        ].concat(
          phases.map((phase) => {
            return {
              key: phase.id,
              name: phase.description || "",
              disabled: false,
            };
          })
        );
        callback(this.state);

        // Get version info
        this.versionModelApi
          .apiGrexmanagerVersionModelRetrieve({ id: versionId })
          .then((versionInfo) => {
            this.state.versionInfoScreen = {
              loadingForm: false,
              formError: "",
              versionId: versionInfo.id,
              versionName: versionInfo.name,
              description: versionInfo.description || "",
              phases: this.state.versionInfoScreen.phases,
              phase: versionInfo.phase || 0,
              author: versionInfo.authorNameFull,
              createdAt: versionInfo.createdAt,
              lastEdited: versionInfo.timestampLastModified,
              lastModifiedByNameFull: versionInfo.lastModifiedByNameFull,
              attachments: versionInfo.attachments,
              projectRole: versionInfo.projectRole,
              viewerUri: versionInfo.viewerUri,
              attachmentToDelete: null,
            };
            this.state.VersionRegionMapScreen.outlineUri =
              versionInfo.outlineUri;
            this.state.FeatureMapScreen.editorUri = versionInfo.editorUri;
            callback(this.state);
          })
          .catch((error) => {
            // TODO: Handle error
            console.log(error);

            this.state.versionInfoScreen.formError =
              "Versie kon niet geladen worden.";
            callback(this.state);
            setTimeout(() => {
              this.state.versionInfoScreen.formError = "";
              callback(this.state);
            }, 5000);
          });
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.versionInfoScreen.formError =
          "Fases konden niet geladen worden.";
        callback(this.state);
        setTimeout(() => {
          this.state.versionInfoScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  versionInfoUpdateForm(
    this: AppStateHandler,
    newFormValues: {
      versionName?: string;
      description?: string;
      phase?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.versionName !== undefined) {
      this.state.versionInfoScreen.versionName = newFormValues.versionName;
    }
    if (newFormValues.description !== undefined) {
      this.state.versionInfoScreen.description = newFormValues.description;
    }
    if (newFormValues.phase !== undefined) {
      this.state.versionInfoScreen.phase = newFormValues.phase;
    }
    callback(this.state);
  }

  versionInfoSave(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.versionInfoScreen.versionName === "" ||
      this.state.versionInfoScreen.phase < 0
    ) {
      this.state.versionInfoScreen.formError =
        "Vul alle velden in die gemarkeerd zijn met een *";
      callback(this.state);
      setTimeout(() => {
        this.state.versionInfoScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else if (
      this.state.versionInfoScreen.versionName
        .split("")
        .some((char) => this.state.unallowedChars.includes(char))
    ) {
      this.state.versionInfoScreen.formError =
        "De naam van de versie mag geen speciale tekens bevatten.";
      callback(this.state);
      setTimeout(() => {
        this.state.versionInfoScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else {
      this.state.loading = true;
      callback(this.state);

      if (this.state.versionInfoScreen.versionId) {
        this.versionModelApi
          .apiGrexmanagerVersionModelUpdate({
            id: this.state.versionInfoScreen.versionId,
            versionModelUpdateRequest: {
              description:
                this.state.versionInfoScreen.description === ""
                  ? null
                  : this.state.versionInfoScreen.description,
              name: this.state.versionInfoScreen.versionName,
              phase:
                this.state.versionInfoScreen.phase === 0
                  ? null
                  : this.state.versionInfoScreen.phase,
            },
          })
          .then((_) => {
            this.state.loading = false;
            callback(this.state);
            this.changeScreen(ScreenState.Projects, callback);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              this.state.versionInfoScreen.formError =
                "Er bestaat al een versie met deze naam.";
            } else {
              this.state.versionInfoScreen.formError =
                "Er ging iets mis tijdens het opslaan.";
            }
            this.state.loading = false;
            callback(this.state);
            setTimeout(() => {
              this.state.versionInfoScreen.formError = "";
              callback(this.state);
            }, 5000);
            console.log(error);
          });
      }
    }
  }

  permanentlyVersionInfoDeleteAttachment(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);

    if (this.state.versionInfoScreen.attachmentToDelete) {
      this.attachmentVersionModelApi
        .apiGrexmanagerAttachmentVersionModelDestroy({
          id: this.state.versionInfoScreen.attachmentToDelete,
        })
        .then((_) => {
          this.state.loading = false;

          if (this.state.projectScreen.selectedVersionModel) {
            this.loadVersionModelInfoScreen(
              this.state.projectScreen.selectedVersionModel,
              callback
            );
          }
        })
        .catch((error) => {
          this.state.versionInfoScreen.formError =
            "Er ging iets mis tijdens het verwijderen.";
          this.state.loading = false;
          callback(this.state);
          setTimeout(() => {
            this.state.versionInfoScreen.formError = "";
            callback(this.state);
          }, 5000);
          console.log(error);
        });
      callback(this.state);
    }
  }

  versionInfoDownloadAttachment(
    this: AppStateHandler,
    attachmentId: number,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.versionInfoScreen.attachments) {
      const attachmentIndex =
        this.state.versionInfoScreen.attachments.findIndex((attachment) => {
          return attachment.id === attachmentId;
        });
      if (attachmentIndex > -1) {
        this.attachmentVersionModelApi
          .apiGrexmanagerAttachmentVersionModelRetrieve({ id: attachmentId })
          .then((blob) => {
            if (this.state.versionInfoScreen.attachments) {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download =
                this.state.versionInfoScreen.attachments[attachmentIndex].name;
              document.body.appendChild(link);
              link.dispatchEvent(
                new MouseEvent("click", {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );
              document.body.removeChild(link);
            }
          })
          .catch((error) => {
            this.state.versionInfoScreen.formError =
              "Er ging iets mis tijdens het verwijderen.";
            callback(this.state);
            setTimeout(() => {
              this.state.versionInfoScreen.formError = "";
              callback(this.state);
            }, 5000);
            console.log(error);
          });
      }
    }
  }
}
