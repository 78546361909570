/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TokenTypeEnum } from './TokenTypeEnum';
import {
    TokenTypeEnumFromJSON,
    TokenTypeEnumFromJSONTyped,
    TokenTypeEnumToJSON,
} from './TokenTypeEnum';

/**
 * 
 * @export
 * @interface OAuthAccessTokenResponse
 */
export interface OAuthAccessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof OAuthAccessTokenResponse
     */
    accessToken: string;
    /**
     * 
     * @type {TokenTypeEnum}
     * @memberof OAuthAccessTokenResponse
     */
    tokenType: TokenTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OAuthAccessTokenResponse
     */
    expiresIn: number;
    /**
     * 
     * @type {string}
     * @memberof OAuthAccessTokenResponse
     */
    refreshToken: string;
}

/**
 * Check if a given object implements the OAuthAccessTokenResponse interface.
 */
export function instanceOfOAuthAccessTokenResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "tokenType" in value;
    isInstance = isInstance && "expiresIn" in value;
    isInstance = isInstance && "refreshToken" in value;

    return isInstance;
}

export function OAuthAccessTokenResponseFromJSON(json: any): OAuthAccessTokenResponse {
    return OAuthAccessTokenResponseFromJSONTyped(json, false);
}

export function OAuthAccessTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OAuthAccessTokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['access_token'],
        'tokenType': TokenTypeEnumFromJSON(json['token_type']),
        'expiresIn': json['expires_in'],
        'refreshToken': json['refresh_token'],
    };
}

export function OAuthAccessTokenResponseToJSON(value?: OAuthAccessTokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_token': value.accessToken,
        'token_type': TokenTypeEnumToJSON(value.tokenType),
        'expires_in': value.expiresIn,
        'refresh_token': value.refreshToken,
    };
}

