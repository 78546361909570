import { RoleEnum } from "@shared/client/lib";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import Button from "@shared/components/Button";
import ButtonList from "@shared/components/ButtonList";
import DropDown from "@shared/components/DropDown";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiSortAlt2 } from "react-icons/bi";
import {
  FiAlertTriangle,
  FiArrowLeft,
  FiCopy,
  FiFilter,
  FiInfo,
  FiLock,
  FiMoreVertical,
  FiMove,
  FiSearch,
  FiXCircle,
} from "react-icons/fi";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, MoveOverviewState, ScreenState } from "../../Types";
import Section from "../../components/Section";
import {
  IndexToProjectSortOption,
  ProjectSortOptionToDropDown,
  ProjectSortOptionToIndex,
} from "../../helperFunctions";
import "./ProjectScreen.css";

interface ProjectScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ProjectScreen extends React.Component<ProjectScreenProps> {
  render() {
    return (
      <div id="ProjectScreen">
        {(() => {
          if (
            this.props.state.projectScreen.moveOverviewState ===
            MoveOverviewState.MoveVersionModel
          ) {
            return (
              <div className="ProjectScreen-MoveOverview">
                <div className="ProjectScreen-MoveDialog">
                  <div className="ProjectScreen-MoveDialogTitle">
                    Versie verplaatsen
                  </div>
                  <div className="ProjectScreen-MoveDialogSubtitle">
                    Kies een project
                  </div>
                  <div className="ProjectScreen-MoveDialogProjectList">
                    {this.props.state.projectScreen.projectsList.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={
                            item.id ===
                            this.props.state.projectScreen
                              .selectedMoveTargetProject
                              ? "ProjectScreen-ListItem ProjectScreen-ListItemSelected"
                              : "ProjectScreen-ListItem"
                          }
                          onClick={() => {
                            this.props.stateHandler.onMoveProjectClick(
                              item.id,
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <span>{item.name}</span>
                          <span className="ProjectScreen-SubText">
                            {item.number ? " - " + item.number : ""}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="ProjectScreen-MoveDialogButtonHolders">
                    <Button
                      onClick={() => {
                        this.props.stateHandler.moveVersionModel(
                          this.props.updateStateCallback
                        );
                      }}
                      disabled={
                        this.props.state.projectScreen
                          .selectedMoveTargetProject === null
                      }
                    >
                      Verplaatsen
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.stateHandler.closeMoveDialog(
                          this.props.updateStateCallback
                        );
                      }}
                    >
                      Annuleren
                    </Button>
                  </div>
                </div>
              </div>
            );
          } else if (
            this.props.state.projectScreen.moveOverviewState ===
            MoveOverviewState.MoveModel
          ) {
            return (
              <div className="ProjectScreen-MoveOverview">
                <div className="ProjectScreen-MoveDialog">
                  {(() => {
                    if (
                      this.props.state.projectScreen
                        .selectedMoveTargetProject !== null
                    ) {
                      return (
                        <div
                          className="ProjectScreen-MoveBackButton"
                          onClick={() => {
                            this.props.stateHandler.moveVersionModelBack(
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <FiArrowLeft />
                        </div>
                      );
                    }
                  })()}
                  <div className="ProjectScreen-MoveDialogTitle">
                    Rekenmodel verplaatsen
                  </div>
                  <div className="ProjectScreen-MoveDialogSubtitle">
                    {this.props.state.projectScreen
                      .selectedMoveTargetProject === null
                      ? "Kies een project"
                      : "Kies een versie"}
                  </div>
                  <div className="ProjectScreen-MoveDialogProjectList">
                    {(() => {
                      if (
                        this.props.state.projectScreen
                          .selectedMoveTargetProject === null
                      ) {
                        return this.props.state.projectScreen.projectsList.map(
                          (item) => {
                            return (
                              <div
                                key={item.id}
                                className={"ProjectScreen-ListItem"}
                                onClick={() => {
                                  this.props.stateHandler.onMoveProjectClick(
                                    item.id,
                                    this.props.updateStateCallback
                                  );
                                }}
                              >
                                <span>{item.name}</span>
                                <span className="ProjectScreen-SubText">
                                  {item.number ? " - " + item.number : ""}
                                </span>
                              </div>
                            );
                          }
                        );
                      } else {
                        return this.props.state.projectScreen.moveVersionModelList.map(
                          (item) => {
                            return (
                              <div
                                key={item.id}
                                className={
                                  item.id ===
                                  this.props.state.projectScreen
                                    .selectedMoveTargetVersionModel
                                    ? "ProjectScreen-ListItem ProjectScreen-ListItemSelected"
                                    : "ProjectScreen-ListItem"
                                }
                                onClick={() => {
                                  this.props.stateHandler.onMoveVersionModelClick(
                                    item.id,
                                    this.props.updateStateCallback
                                  );
                                }}
                              >
                                <span>{item.name}</span>
                              </div>
                            );
                          }
                        );
                      }
                    })()}
                  </div>
                  <div className="ProjectScreen-MoveDialogButtonHolders">
                    <Button
                      onClick={() => {
                        this.props.stateHandler.moveModel(
                          this.props.updateStateCallback
                        );
                      }}
                      disabled={
                        this.props.state.projectScreen
                          .selectedMoveTargetProject === null ||
                        this.props.state.projectScreen
                          .selectedMoveTargetVersionModel === null
                      }
                    >
                      Verplaatsen
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.stateHandler.closeMoveDialog(
                          this.props.updateStateCallback
                        );
                      }}
                    >
                      Annuleren
                    </Button>
                  </div>
                </div>
              </div>
            );
          }
        })()}

        <BreadCrumbs
          data={[{ title: "GrexManager" }, { title: "Projecten" }]}
        ></BreadCrumbs>

        {(() => {
          if (this.props.state.projectScreen.errorMessage !== "") {
            return (
              <Alert
                Error={true}
                Message={this.props.state.projectScreen.errorMessage}
              ></Alert>
            );
          }
        })()}

        <div id="ProjectScreen-SearchSortFilterHolder">
          <Section id="ProjectScreen-SearchSortFilterSection">
            <FiSearch style={{ marginTop: "7px" }} />
            <TextInput
              type={TextInputType.Text}
              placeholder="Projectnaam"
              value={this.props.state.projectScreen.searchProject}
              onChange={(newValue: string) => {
                this.props.stateHandler.onSearchChange(
                  newValue,
                  this.props.updateStateCallback
                );
              }}
            ></TextInput>
            <Button
              onClick={() => {
                this.props.stateHandler.searchProjectListAndSelectFirst(
                  this.props.updateStateCallback
                );
              }}
              noMargin={true}
            >
              Zoek
            </Button>
          </Section>

          <Section id="ProjectScreen-SearchSortFilterSection">
            <FiFilter style={{ marginTop: "7px" }} />
            <DropDown
              list={this.props.state.projectScreen.typeProjectList}
              onChange={(newKey) => {
                this.props.stateHandler.onProjectFilterChange(
                  { typeProject: newKey },
                  this.props.updateStateCallback
                );
              }}
              selectedKey={this.props.state.projectScreen.filterTypeProject}
            ></DropDown>
            <DropDown
              list={this.props.state.projectScreen.subregions}
              onChange={(newKey) => {
                this.props.stateHandler.onProjectFilterChange(
                  { subregion: newKey },
                  this.props.updateStateCallback
                );
              }}
              selectedKey={this.props.state.projectScreen.filterSubregion}
            ></DropDown>
          </Section>

          <Section id="ProjectScreen-SearchSortFilterSection">
            <BiSortAlt2 style={{ marginTop: "7px" }} />
            <DropDown
              list={ProjectSortOptionToDropDown()}
              onChange={(newKey) => {
                this.props.stateHandler.updateProjectSorting(
                  IndexToProjectSortOption(newKey),
                  this.props.updateStateCallback
                );
              }}
              selectedKey={ProjectSortOptionToIndex(
                this.props.state.projectScreen.projectSorting
              )}
            ></DropDown>
          </Section>
        </div>

        <div id="ProjectScreen-ScrollView">
          <div id="ProjectScreen-ScrollHolder">
            <div className="ProjectScreen-Section">
              <div className="ProjectScreen-SectionHeader">
                <div className="ProjectScreen-SectionHeaderTitle">
                  Projecten
                </div>
                {(() => {
                  if (
                    this.props.state.currentUser.userRole === RoleEnum.Adm ||
                    this.props.state.currentUser.userRole === RoleEnum.Usr
                  ) {
                    return (
                      <div
                        className="ProjectScreen-SectionHeaderButton"
                        onClick={() => {
                          this.props.stateHandler.changeScreen(
                            ScreenState.Projects_NewProject,
                            this.props.updateStateCallback
                          );
                        }}
                      >
                        +
                      </div>
                    );
                  }
                })()}
              </div>
              <div className="ProjectScreen-SectionList">
                {this.props.state.projectScreen.projectsList.map((item) => {
                  if (item.visible && item.searchVisible) {
                    return (
                      <div
                        key={item.id}
                        className={
                          item.id ===
                          this.props.state.projectScreen.selectedProject
                            ? "ProjectScreen-ListItem ProjectScreen-ListItemTwoButtons ProjectScreen-ListItemSelected"
                            : "ProjectScreen-ListItem ProjectScreen-ListItemTwoButtons"
                        }
                        onClick={() => {
                          this.props.stateHandler.onProjectClick(
                            item.id,
                            this.props.updateStateCallback
                          );
                        }}
                      >
                        <span>{item.name}</span>
                        <span className="ProjectScreen-SubText">
                          {item.number ? " - " + item.number : ""}
                        </span>
                        <div className="ProjectScreen-ListItemButtonHolder">
                          <div
                            className="ProjectScreen-ListItemButton"
                            data-type="project"
                            data-action="moreInfo"
                            onClick={() => {
                              this.props.stateHandler.onProjectMoreInfoClick(
                                item.id,
                                this.props.updateStateCallback
                              );
                            }}
                          >
                            <FiInfo />
                          </div>
                          <div
                            className="ProjectScreen-ListItemButton"
                            data-type="project"
                            data-action="subMenu"
                            onClick={(event) => {
                              this.props.stateHandler.openProjectScreenSubMenu(
                                item.id,
                                null,
                                null,
                                event.pageX,
                                event.pageY,
                                window.innerHeight,
                                window.innerWidth,
                                this.props.updateStateCallback
                              );
                            }}
                          >
                            <FiMoreVertical />
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
              <div
                className={
                  this.props.state.projectScreen.loadingProjects
                    ? "ProjectScreen-SectionDisabledOverlay disabled"
                    : "ProjectScreen-SectionDisabledOverlay"
                }
              ></div>
            </div>

            <div className="ProjectScreen-Divider"></div>

            <div className="ProjectScreen-Section">
              <div className="ProjectScreen-SectionHeader">
                <div className="ProjectScreen-SectionHeaderTitle">Versies</div>
                {(() => {
                  if (this.props.state.projectScreen.selectedProject !== null) {
                    const projectIndex =
                      this.props.state.projectScreen.projectsList.findIndex(
                        (object) => {
                          return (
                            object.id ===
                            this.props.state.projectScreen.selectedProject
                          );
                        }
                      );
                    if (projectIndex > -1) {
                      if (
                        this.props.state.projectScreen.projectsList[
                          projectIndex
                        ].role === RoleEnum.Adm ||
                        this.props.state.projectScreen.projectsList[
                          projectIndex
                        ].role === RoleEnum.Usr
                      ) {
                        return (
                          <div
                            className="ProjectScreen-SectionHeaderButton"
                            onClick={() => {
                              this.props.stateHandler.changeScreen(
                                ScreenState.Projects_NewVersionModel,
                                this.props.updateStateCallback
                              );
                            }}
                          >
                            +
                          </div>
                        );
                      }
                    }
                  }
                })()}
              </div>
              <div className="ProjectScreen-SectionList">
                {this.props.state.projectScreen.versionsList.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={
                        item.id ===
                        this.props.state.projectScreen.selectedVersionModel
                          ? "ProjectScreen-ListItem ProjectScreen-ListItemTwoButtons ProjectScreen-ListItemSelected"
                          : "ProjectScreen-ListItem ProjectScreen-ListItemTwoButtons"
                      }
                      onClick={() => {
                        this.props.stateHandler.onVersionModelClick(
                          item.id,
                          this.props.updateStateCallback
                        );
                      }}
                    >
                      <span>{item.name}</span>
                      <div className="ProjectScreen-ListItemButtonHolder">
                        <div
                          className="ProjectScreen-ListItemButton"
                          data-type="version"
                          data-action="moreInfo"
                          onClick={() => {
                            this.props.stateHandler.onVersionModelMoreInfoClick(
                              item.id,
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <FiInfo />
                        </div>
                        <div
                          className="ProjectScreen-ListItemButton"
                          data-type="version"
                          data-action="subMenu"
                          onClick={(event) => {
                            this.props.stateHandler.openProjectScreenSubMenu(
                              null,
                              item.id,
                              null,
                              event.pageX,
                              event.pageY,
                              window.innerHeight,
                              window.innerWidth,
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <FiMoreVertical />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className={
                  this.props.state.projectScreen.loadingVersionModels
                    ? "ProjectScreen-SectionDisabledOverlay disabled"
                    : "ProjectScreen-SectionDisabledOverlay"
                }
              ></div>
            </div>

            <div className="ProjectScreen-Divider"></div>

            <div className="ProjectScreen-Section">
              <div className="ProjectScreen-SectionHeader">
                <div className="ProjectScreen-SectionHeaderTitle">
                  Rekenmodellen
                </div>
                {(() => {
                  if (this.props.state.projectScreen.selectedProject !== null) {
                    const projectIndex =
                      this.props.state.projectScreen.projectsList.findIndex(
                        (object) => {
                          return (
                            object.id ===
                            this.props.state.projectScreen.selectedProject
                          );
                        }
                      );
                    if (projectIndex > -1) {
                      if (
                        this.props.state.projectScreen.projectsList[
                          projectIndex
                        ].role === RoleEnum.Adm ||
                        this.props.state.projectScreen.projectsList[
                          projectIndex
                        ].role === RoleEnum.Usr
                      ) {
                        return (
                          <div
                            className="ProjectScreen-SectionHeaderButton"
                            onClick={() => {
                              this.props.stateHandler.changeScreen(
                                ScreenState.Projects_NewModel,
                                this.props.updateStateCallback
                              );
                            }}
                          >
                            +
                          </div>
                        );
                      }
                    }
                  }
                })()}
              </div>
              <div className="ProjectScreen-SectionList">
                {this.props.state.projectScreen.modelsList.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={
                        "ProjectScreen-ListItem ProjectScreen-ListItemFiveButtons"
                      }
                    >
                      <span
                        onClick={() => {
                          this.props.stateHandler.onModelClick(
                            item.id,
                            this.props.updateStateCallback
                          );
                        }}
                      >
                        <span>{item.name}</span>
                        <span className="ProjectScreen-SubText">
                          {" "}
                          - {item.typeModelName}
                        </span>
                      </span>
                      <div className="ProjectScreen-ListItemButtonHolder">
                        {(() => {
                          if (item.alreadyOpen) {
                            return (
                              <div
                                className="ProjectScreen-ListItemButton"
                                data-type="model"
                                data-action="cancelSessionEdit"
                                onClick={() => {
                                  if (
                                    this.props.state.projectScreen
                                      .selectedProject !== null
                                  ) {
                                    const projectIndex =
                                      this.props.state.projectScreen.projectsList.findIndex(
                                        (object) => {
                                          return (
                                            object.id ===
                                            this.props.state.projectScreen
                                              .selectedProject
                                          );
                                        }
                                      );
                                    const modelIndex =
                                      this.props.state.projectScreen.modelsList.findIndex(
                                        (object) => {
                                          return object.id === item.id;
                                        }
                                      );
                                    if (projectIndex > -1) {
                                      if (
                                        this.props.state.projectScreen
                                          .projectsList[projectIndex].role ===
                                          RoleEnum.Adm ||
                                        this.props.state.projectScreen
                                          .modelsList[modelIndex]
                                          .openByCurrentUser
                                      ) {
                                        this.props.stateHandler.onModelCancelSessionEditClick(
                                          item.id,
                                          this.props.updateStateCallback
                                        );
                                      }
                                    }
                                  }
                                }}
                              >
                                <FiAlertTriangle style={{ color: "#ee592f" }} />
                              </div>
                            );
                          }
                        })()}
                        {(() => {
                          if (item.frozen) {
                            return (
                              <div
                                className="ProjectScreen-ListItemButton"
                                data-type="model"
                                data-action="frozenModel"
                                onClick={() => {}}
                              >
                                <FiLock />
                              </div>
                            );
                          }
                        })()}
                        <div
                          className="ProjectScreen-ListItemButton"
                          data-type="model"
                          data-action="moreInfo"
                          onClick={() => {
                            this.props.stateHandler.onModelMoreInfoClick(
                              item.id,
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <FiInfo />
                        </div>
                        <div
                          className="ProjectScreen-ListItemButton"
                          data-type="model"
                          data-action="subMenu"
                          onClick={(event) => {
                            this.props.stateHandler.openProjectScreenSubMenu(
                              null,
                              null,
                              item.id,
                              event.pageX,
                              event.pageY,
                              window.innerHeight,
                              window.innerWidth,
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <FiMoreVertical />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className={
                  this.props.state.projectScreen.loadingModels
                    ? "ProjectScreen-SectionDisabledOverlay disabled"
                    : "ProjectScreen-SectionDisabledOverlay"
                }
              ></div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "12px" }}>
          <ButtonList
            data={[
              {
                title: "Bekijk op kaart",
                callback: () => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.MapViewer_Overview,
                    this.props.updateStateCallback
                  );
                },
                disabled: false,
              },
            ]}
          ></ButtonList>
        </div>

        {/* Submenu / pop-up for projects */}
        <div
          id="ProjectScreen-ProjectSubMenu"
          style={
            this.props.state.projectScreen.projectPopUpProjectId !== null
              ? {
                  display: "block",
                  top: this.props.state.projectScreen.popUpPosition.y,
                  left: this.props.state.projectScreen.popUpPosition.x,
                }
              : { display: "none" }
          }
          onMouseLeave={() => {
            this.props.stateHandler.closeProjectScreenSubMenu(
              this.props.updateStateCallback
            );
          }}
        >
          <div
            className="ProjectScreen-ProjectSubMenuItem"
            onClick={() => {
              if (
                this.props.state.projectScreen.projectPopUpProjectId !== null
              ) {
                this.props.stateHandler.onProjectMoreInfoClick(
                  this.props.state.projectScreen.projectPopUpProjectId,
                  this.props.updateStateCallback
                );
              }
            }}
          >
            <div className="ProjectScreen-ProjectSubMenuIcon">
              <FiInfo />
            </div>
            <div className="ProjectScreen-ProjectSubMenuText">Meer info</div>
          </div>

          {(() => {
            if (this.props.state.projectScreen.selectedProject !== null) {
              const projectIndex =
                this.props.state.projectScreen.projectsList.findIndex(
                  (object) => {
                    return (
                      object.id ===
                      this.props.state.projectScreen.selectedProject
                    );
                  }
                );
              if (projectIndex > -1) {
                if (
                  this.props.state.projectScreen.projectsList[projectIndex]
                    .role === RoleEnum.Adm ||
                  this.props.state.projectScreen.projectsList[projectIndex]
                    .role === RoleEnum.Usr
                ) {
                  return (
                    <>
                      <div
                        className="ProjectScreen-ProjectSubMenuItem"
                        onClick={() => {
                          if (
                            this.props.state.projectScreen
                              .projectPopUpProjectId !== null
                          ) {
                            this.props.stateHandler.onProjectDuplicateClick(
                              this.props.state.projectScreen
                                .projectPopUpProjectId,
                              this.props.updateStateCallback
                            );
                          }
                        }}
                      >
                        <div className="ProjectScreen-ProjectSubMenuIcon">
                          <FiCopy />
                        </div>
                        <div className="ProjectScreen-ProjectSubMenuText">
                          Dupliceren
                        </div>
                      </div>
                      <div
                        className="ProjectScreen-ProjectSubMenuItem"
                        onClick={() => {
                          if (
                            this.props.state.projectScreen
                              .projectPopUpProjectId !== null
                          ) {
                            this.props.stateHandler.onProjectDeleteClick(
                              this.props.state.projectScreen
                                .projectPopUpProjectId,
                              this.props.updateStateCallback
                            );
                          }
                        }}
                      >
                        <div className="ProjectScreen-ProjectSubMenuIcon">
                          <AiOutlineDelete />
                        </div>
                        <div className="ProjectScreen-ProjectSubMenuText">
                          Verwijderen
                        </div>
                      </div>
                    </>
                  );
                }
              }
            }
          })()}
        </div>

        {/* Submenu / pop-up for versions */}
        <div
          id="ProjectScreen-ProjectSubMenu"
          style={
            this.props.state.projectScreen.projectPopUpVersionModelId !== null
              ? {
                  display: "block",
                  top: this.props.state.projectScreen.popUpPosition.y,
                  left: this.props.state.projectScreen.popUpPosition.x,
                }
              : { display: "none" }
          }
          onMouseLeave={() => {
            this.props.stateHandler.closeProjectScreenSubMenu(
              this.props.updateStateCallback
            );
          }}
        >
          <div
            className="ProjectScreen-ProjectSubMenuItem"
            onClick={() => {
              if (
                this.props.state.projectScreen.projectPopUpVersionModelId !==
                null
              ) {
                this.props.stateHandler.onVersionModelMoreInfoClick(
                  this.props.state.projectScreen.projectPopUpVersionModelId,
                  this.props.updateStateCallback
                );
              }
            }}
          >
            <div className="ProjectScreen-ProjectSubMenuIcon">
              <FiInfo />
            </div>
            <div className="ProjectScreen-ProjectSubMenuText">Meer info</div>
          </div>

          {(() => {
            if (this.props.state.projectScreen.selectedProject !== null) {
              const projectIndex =
                this.props.state.projectScreen.projectsList.findIndex(
                  (object) => {
                    return (
                      object.id ===
                      this.props.state.projectScreen.selectedProject
                    );
                  }
                );
              if (projectIndex > -1) {
                if (
                  this.props.state.projectScreen.projectsList[projectIndex]
                    .role === RoleEnum.Adm ||
                  this.props.state.projectScreen.projectsList[projectIndex]
                    .role === RoleEnum.Usr
                ) {
                  return (
                    <>
                      <div
                        className="ProjectScreen-ProjectSubMenuItem"
                        onClick={() => {
                          if (
                            this.props.state.projectScreen
                              .projectPopUpVersionModelId !== null
                          ) {
                            this.props.stateHandler.onVersionModelDuplicateClick(
                              this.props.state.projectScreen
                                .projectPopUpVersionModelId,
                              this.props.updateStateCallback
                            );
                          }
                        }}
                      >
                        <div className="ProjectScreen-ProjectSubMenuIcon">
                          <FiCopy />
                        </div>
                        <div className="ProjectScreen-ProjectSubMenuText">
                          Dupliceren
                        </div>
                      </div>
                      <div
                        className="ProjectScreen-ProjectSubMenuItem"
                        onClick={() => {
                          if (
                            this.props.state.projectScreen
                              .projectPopUpVersionModelId !== null
                          ) {
                            this.props.stateHandler.onVersionModelMoveClick(
                              this.props.state.projectScreen
                                .projectPopUpVersionModelId,
                              this.props.updateStateCallback
                            );
                          }
                        }}
                      >
                        <div className="ProjectScreen-ProjectSubMenuIcon">
                          <FiMove />
                        </div>
                        <div className="ProjectScreen-ProjectSubMenuText">
                          Verplaatsen
                        </div>
                      </div>
                      <div
                        className="ProjectScreen-ProjectSubMenuItem"
                        onClick={() => {
                          if (
                            this.props.state.projectScreen
                              .projectPopUpVersionModelId !== null
                          ) {
                            this.props.stateHandler.onVersionModelDeleteClick(
                              this.props.state.projectScreen
                                .projectPopUpVersionModelId,
                              this.props.updateStateCallback
                            );
                          }
                        }}
                      >
                        <div className="ProjectScreen-ProjectSubMenuIcon">
                          <AiOutlineDelete />
                        </div>
                        <div className="ProjectScreen-ProjectSubMenuText">
                          Verwijderen
                        </div>
                      </div>
                      {/* <div className="ProjectScreen-ProjectSubMenuItem" onClick={() => {
                                            if(this.props.state.projectScreen.projectPopUpVersionModelId !== null) {
                                                this.props.stateHandler.onVersionModelAddAttachmentClick(this.props.state.projectScreen.projectPopUpVersionModelId, this.props.updateStateCallback);
                                            }
                                        }}>
                                            <div className="ProjectScreen-ProjectSubMenuIcon"><MoreInfoIcon/></div>
                                            <div className="ProjectScreen-ProjectSubMenuText">Bijlage toevoegen</div>
                                        </div> */}
                    </>
                  );
                }
              }
            }
          })()}
        </div>

        {/* Submenu / pop-up for calc models */}
        <div
          id="ProjectScreen-ProjectSubMenu"
          style={
            this.props.state.projectScreen.projectPopUpModelId !== null
              ? {
                  display: "block",
                  top: this.props.state.projectScreen.popUpPosition.y,
                  left: this.props.state.projectScreen.popUpPosition.x,
                }
              : { display: "none" }
          }
          onMouseLeave={() => {
            this.props.stateHandler.closeProjectScreenSubMenu(
              this.props.updateStateCallback
            );
          }}
        >
          <div
            className="ProjectScreen-ProjectSubMenuItem"
            onClick={() => {
              if (this.props.state.projectScreen.projectPopUpModelId !== null) {
                this.props.stateHandler.onModelMoreInfoClick(
                  this.props.state.projectScreen.projectPopUpModelId,
                  this.props.updateStateCallback
                );
              }
            }}
          >
            <div className="ProjectScreen-ProjectSubMenuIcon">
              <FiInfo />
            </div>
            <div className="ProjectScreen-ProjectSubMenuText">Meer info</div>
          </div>

          {(() => {
            if (this.props.state.projectScreen.selectedProject !== null) {
              const projectIndex =
                this.props.state.projectScreen.projectsList.findIndex(
                  (object) => {
                    return (
                      object.id ===
                      this.props.state.projectScreen.selectedProject
                    );
                  }
                );
              if (projectIndex > -1) {
                if (
                  this.props.state.projectScreen.projectsList[projectIndex]
                    .role === RoleEnum.Adm ||
                  this.props.state.projectScreen.projectsList[projectIndex]
                    .role === RoleEnum.Usr
                ) {
                  return (
                    <>
                      {(() => {
                        const modelIndex =
                          this.props.state.projectScreen.modelsList.findIndex(
                            (object) => {
                              return (
                                object.id ===
                                this.props.state.projectScreen
                                  .projectPopUpModelId
                              );
                            }
                          );

                        if (
                          // If the current user is an Admin (RoleEnum.Adm) and the model is already open (alreadyOpen).
                          // OR If the model is already open (alreadyOpen) and it was opened by the current user (openByCurrentUser).
                          (modelIndex > -1 &&
                            this.props.state.projectScreen.projectsList[
                              projectIndex
                            ].role === RoleEnum.Adm &&
                            this.props.state.projectScreen.modelsList[
                              modelIndex
                            ].alreadyOpen) ||
                          (modelIndex > -1 &&
                            this.props.state.projectScreen.modelsList[
                              modelIndex
                            ].alreadyOpen &&
                            this.props.state.projectScreen.modelsList[
                              modelIndex
                            ].openByCurrentUser)
                        ) {
                          return (
                            <div
                              className="ProjectScreen-ProjectSubMenuItem"
                              onClick={() => {
                                if (
                                  this.props.state.projectScreen
                                    .projectPopUpModelId !== null
                                ) {
                                  this.props.stateHandler.onModelCancelSessionEditClick(
                                    this.props.state.projectScreen
                                      .projectPopUpModelId,
                                    this.props.updateStateCallback
                                  );
                                }
                              }}
                            >
                              <div className="ProjectScreen-ProjectSubMenuIcon">
                                <FiXCircle />
                              </div>
                              <div className="ProjectScreen-ProjectSubMenuText">
                                Beëindig sessie
                              </div>
                            </div>
                          );
                        }
                      })()}
                      <div
                        className="ProjectScreen-ProjectSubMenuItem"
                        onClick={() => {
                          if (
                            this.props.state.projectScreen
                              .projectPopUpModelId !== null
                          ) {
                            this.props.stateHandler.onModelDuplicateClick(
                              this.props.state.projectScreen
                                .projectPopUpModelId,
                              this.props.updateStateCallback
                            );
                          }
                        }}
                      >
                        <div className="ProjectScreen-ProjectSubMenuIcon">
                          <FiCopy />
                        </div>
                        <div className="ProjectScreen-ProjectSubMenuText">
                          Dupliceren
                        </div>
                      </div>
                      <div
                        className="ProjectScreen-ProjectSubMenuItem"
                        onClick={() => {
                          if (
                            this.props.state.projectScreen
                              .projectPopUpModelId !== null
                          ) {
                            this.props.stateHandler.onModelMoveClick(
                              this.props.state.projectScreen
                                .projectPopUpModelId,
                              this.props.updateStateCallback
                            );
                          }
                        }}
                      >
                        <div className="ProjectScreen-ProjectSubMenuIcon">
                          <FiMove />
                        </div>
                        <div className="ProjectScreen-ProjectSubMenuText">
                          Verplaatsen
                        </div>
                      </div>
                      <div
                        className="ProjectScreen-ProjectSubMenuItem"
                        onClick={() => {
                          if (
                            this.props.state.projectScreen
                              .projectPopUpModelId !== null
                          ) {
                            this.props.stateHandler.onModelDeleteClick(
                              this.props.state.projectScreen
                                .projectPopUpModelId,
                              this.props.updateStateCallback
                            );
                          }
                        }}
                      >
                        <div className="ProjectScreen-ProjectSubMenuIcon">
                          <AiOutlineDelete />
                        </div>
                        <div className="ProjectScreen-ProjectSubMenuText">
                          Verwijderen
                        </div>
                      </div>
                    </>
                  );
                }
              }
            }
          })()}
        </div>
      </div>
    );
  }
}
