/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `UITGANGSPUNT` - UITGANGSPUNT
 * * `PER_01` - PER_01
 * * `PER_02` - PER_02
 * * `PER_03` - PER_03
 * * `PER_04` - PER_04
 * * `PER_05` - PER_05
 * * `PER_06` - PER_06
 * * `PER_07` - PER_07
 * * `PER_08` - PER_08
 * * `PER_09` - PER_09
 * * `PER_10` - PER_10
 * * `PER_11` - PER_11
 * * `PER_12` - PER_12
 * * `PER_13` - PER_13
 * * `PER_14` - PER_14
 * * `PER_15` - PER_15
 * * `PER_16` - PER_16
 * * `PER_17` - PER_17
 * * `PER_18` - PER_18
 * * `PER_19` - PER_19
 * * `PER_20` - PER_20
 * * `PER_21` - PER_21
 * * `PER_22` - PER_22
 * * `PER_23` - PER_23
 * * `PER_24` - PER_24
 * * `PER_25` - PER_25
 * * `PER_26` - PER_26
 * * `PER_27` - PER_27
 * * `PER_28` - PER_28
 * * `PER_29` - PER_29
 * * `PER_30` - PER_30
 * * `PER_31` - PER_31
 * * `PER_32` - PER_32
 * * `PER_33` - PER_33
 * * `PER_34` - PER_34
 * * `PER_35` - PER_35
 * * `PER_36` - PER_36
 * * `PER_37` - PER_37
 * * `PER_38` - PER_38
 * * `PER_39` - PER_39
 * * `PER_40` - PER_40
 * @export
 */
export const PeriodTypeEnum = {
    Uitgangspunt: 'UITGANGSPUNT',
    Per01: 'PER_01',
    Per02: 'PER_02',
    Per03: 'PER_03',
    Per04: 'PER_04',
    Per05: 'PER_05',
    Per06: 'PER_06',
    Per07: 'PER_07',
    Per08: 'PER_08',
    Per09: 'PER_09',
    Per10: 'PER_10',
    Per11: 'PER_11',
    Per12: 'PER_12',
    Per13: 'PER_13',
    Per14: 'PER_14',
    Per15: 'PER_15',
    Per16: 'PER_16',
    Per17: 'PER_17',
    Per18: 'PER_18',
    Per19: 'PER_19',
    Per20: 'PER_20',
    Per21: 'PER_21',
    Per22: 'PER_22',
    Per23: 'PER_23',
    Per24: 'PER_24',
    Per25: 'PER_25',
    Per26: 'PER_26',
    Per27: 'PER_27',
    Per28: 'PER_28',
    Per29: 'PER_29',
    Per30: 'PER_30',
    Per31: 'PER_31',
    Per32: 'PER_32',
    Per33: 'PER_33',
    Per34: 'PER_34',
    Per35: 'PER_35',
    Per36: 'PER_36',
    Per37: 'PER_37',
    Per38: 'PER_38',
    Per39: 'PER_39',
    Per40: 'PER_40'
} as const;
export type PeriodTypeEnum = typeof PeriodTypeEnum[keyof typeof PeriodTypeEnum];


export function PeriodTypeEnumFromJSON(json: any): PeriodTypeEnum {
    return PeriodTypeEnumFromJSONTyped(json, false);
}

export function PeriodTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodTypeEnum {
    return json as PeriodTypeEnum;
}

export function PeriodTypeEnumToJSON(value?: PeriodTypeEnum | null): any {
    return value as any;
}

