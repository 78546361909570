/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TemplateReportResponse,
} from '../models';
import {
    TemplateReportResponseFromJSON,
    TemplateReportResponseToJSON,
} from '../models';

export interface ApiGrexmanagerTemplateReportListRequest {
    filter?: Array<string>;
}

/**
 * 
 */
export class TemplateReportApi extends runtime.BaseAPI {

    /**
     * Endpoint for receiving a list of template reports
     */
    async apiGrexmanagerTemplateReportListRaw(requestParameters: ApiGrexmanagerTemplateReportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TemplateReportResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.filter) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/template-report/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemplateReportResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of template reports
     */
    async apiGrexmanagerTemplateReportList(requestParameters: ApiGrexmanagerTemplateReportListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TemplateReportResponse>> {
        const response = await this.apiGrexmanagerTemplateReportListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
