/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MapViewerClientRegionResponse
 */
export interface MapViewerClientRegionResponse {
    /**
     * 
     * @type {string}
     * @memberof MapViewerClientRegionResponse
     */
    outlineUri: string;
}

/**
 * Check if a given object implements the MapViewerClientRegionResponse interface.
 */
export function instanceOfMapViewerClientRegionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "outlineUri" in value;

    return isInstance;
}

export function MapViewerClientRegionResponseFromJSON(json: any): MapViewerClientRegionResponse {
    return MapViewerClientRegionResponseFromJSONTyped(json, false);
}

export function MapViewerClientRegionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapViewerClientRegionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'outlineUri': json['outline_uri'],
    };
}

export function MapViewerClientRegionResponseToJSON(value?: MapViewerClientRegionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'outline_uri': value.outlineUri,
    };
}

