/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParameterListTypeEnum } from './ParameterListTypeEnum';
import {
    ParameterListTypeEnumFromJSON,
    ParameterListTypeEnumFromJSONTyped,
    ParameterListTypeEnumToJSON,
} from './ParameterListTypeEnum';
import type { PeriodTypeEnum } from './PeriodTypeEnum';
import {
    PeriodTypeEnumFromJSON,
    PeriodTypeEnumFromJSONTyped,
    PeriodTypeEnumToJSON,
} from './PeriodTypeEnum';

/**
 * 
 * @export
 * @interface ParameterList
 */
export interface ParameterList {
    /**
     * 
     * @type {ParameterListTypeEnum}
     * @memberof ParameterList
     */
    type: ParameterListTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ParameterList
     */
    rowIndex?: number | null;
    /**
     * 
     * @type {PeriodTypeEnum}
     * @memberof ParameterList
     */
    periodType?: PeriodTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ParameterList
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterList
     */
    valueFloat?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ParameterList
     */
    valueDate?: Date | null;
}

/**
 * Check if a given object implements the ParameterList interface.
 */
export function instanceOfParameterList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ParameterListFromJSON(json: any): ParameterList {
    return ParameterListFromJSONTyped(json, false);
}

export function ParameterListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': ParameterListTypeEnumFromJSON(json['type']),
        'rowIndex': !exists(json, 'row_index') ? undefined : json['row_index'],
        'periodType': !exists(json, 'period_type') ? undefined : PeriodTypeEnumFromJSON(json['period_type']),
        'year': !exists(json, 'year') ? undefined : json['year'],
        'valueFloat': !exists(json, 'value_float') ? undefined : json['value_float'],
        'valueDate': !exists(json, 'value_date') ? undefined : (json['value_date'] === null ? null : new Date(json['value_date'])),
    };
}

export function ParameterListToJSON(value?: ParameterList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ParameterListTypeEnumToJSON(value.type),
        'row_index': value.rowIndex,
        'period_type': PeriodTypeEnumToJSON(value.periodType),
        'year': value.year,
        'value_float': value.valueFloat,
        'value_date': value.valueDate === undefined ? undefined : (value.valueDate === null ? null : value.valueDate.toISOString().substr(0,10)),
    };
}

