import { RoleEnum, TypeEnum } from "@shared/client/lib";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import DropDown from "@shared/components/DropDown";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import {
  dropDownListPrintItem,
  formatDate,
} from "@shared/utils/helperFunctions";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, PopUpState, ScreenState } from "../../Types";
import "./VersionModelInfoScreen.css";

interface VersionModelInfoScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class VersionModelInfoScreen extends React.Component<VersionModelInfoScreenProps> {
  render() {
    return (
      <div id="VersionModelInfoScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Projecten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Versie info",
            },
          ]}
        ></BreadCrumbs>

        <Section disabled={this.props.state.versionInfoScreen.loadingForm}>
          {(() => {
            if (this.props.state.versionInfoScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.versionInfoScreen.formError}
                ></Alert>
              );
            }
          })()}
          <table className="VersionModelInfoScreen-Table">
            <tbody>
              <tr>
                <td>Versienaam*</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.versionInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.versionInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Versienaam"
                          onChange={(newValue) => {
                            this.props.stateHandler.versionInfoUpdateForm(
                              { versionName: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={this.props.state.versionInfoScreen.versionName}
                        ></TextInput>
                      );
                    } else {
                      return this.props.state.versionInfoScreen.versionName;
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Beschrijving</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.versionInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.versionInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Beschrijving"
                          onChange={(newValue) => {
                            this.props.stateHandler.versionInfoUpdateForm(
                              { description: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={this.props.state.versionInfoScreen.description}
                        ></TextInput>
                      );
                    } else {
                      return this.props.state.versionInfoScreen.description ===
                        ""
                        ? "-"
                        : this.props.state.versionInfoScreen.description;
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Fase</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.versionInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.versionInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <DropDown
                          list={this.props.state.versionInfoScreen.phases}
                          onChange={(newKey) => {
                            this.props.stateHandler.versionInfoUpdateForm(
                              { phase: newKey },
                              this.props.updateStateCallback
                            );
                          }}
                          selectedKey={this.props.state.versionInfoScreen.phase}
                        ></DropDown>
                      );
                    } else {
                      return this.props.state.versionInfoScreen.phase === 0
                        ? "-"
                        : dropDownListPrintItem(
                            this.props.state.versionInfoScreen.phases,
                            this.props.state.versionInfoScreen.phase
                          );
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Aangemaakt op</td>
                <td>
                  {this.props.state.versionInfoScreen.createdAt
                    ? formatDate(this.props.state.versionInfoScreen.createdAt)
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Aangemaakt door</td>
                <td>{this.props.state.versionInfoScreen.author}</td>
              </tr>
              <tr>
                <td>Laatst gewijzigd op</td>
                <td>
                  {this.props.state.versionInfoScreen.lastEdited
                    ? formatDate(this.props.state.versionInfoScreen.lastEdited)
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Laatst gewijzigd door</td>
                <td>
                  {this.props.state.versionInfoScreen.lastModifiedByNameFull}
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section disabled={this.props.state.versionInfoScreen.loadingForm}>
          <table className="VersionModelInfoScreen-Table">
            <thead>
              <tr>
                <th style={{ minWidth: "100px" }}>Naam</th>
                <th style={{ minWidth: "100px" }}>Bijlagecategorie</th>
                <th style={{ minWidth: "16px" }}></th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                if (this.props.state.versionInfoScreen.attachments === null) {
                  return (
                    <tr>
                      <td>Aan het laden...</td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                } else if (
                  this.props.state.versionInfoScreen.attachments !== null &&
                  this.props.state.versionInfoScreen.attachments.length === 0
                ) {
                  return (
                    <tr>
                      <td>Geen bijlagen toegevoegd</td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                }
              })()}
              {this.props.state.versionInfoScreen.attachments?.map((item) => {
                if (item.type === TypeEnum.Link && item.urlLink) {
                  return (
                    <tr key={item.id}>
                      <td>
                        <a href={item.urlLink} target="blank">
                          {item.name}
                        </a>
                      </td>
                      <td>{item.categoryName}</td>
                      <td className="VersionModelInfoScreen-DeleteTd">
                        <AiOutlineDelete
                          onClick={() => {
                            this.props.state.versionInfoScreen.attachmentToDelete =
                              item.id;
                            this.props.state.popUpState =
                              PopUpState.DeleteAttachmentAlert;
                            this.props.updateStateCallback(this.props.state);
                          }}
                        />
                      </td>
                    </tr>
                  );
                } else if (item.type === TypeEnum.File) {
                  return (
                    <tr key={item.id}>
                      <td>
                        <span
                          onClick={() => {
                            this.props.stateHandler.versionInfoDownloadAttachment(
                              item.id,
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          {item.name}
                        </span>
                      </td>
                      <td>{item.categoryName}</td>
                      <td className="VersionModelInfoScreen-DeleteTd">
                        <AiOutlineDelete
                          onClick={() => {
                            this.props.state.versionInfoScreen.attachmentToDelete =
                              item.id;
                            this.props.state.popUpState =
                              PopUpState.DeleteAttachmentAlert;
                            this.props.updateStateCallback(this.props.state);
                          }}
                        />
                      </td>
                    </tr>
                  );
                } else {
                  return <></>;
                }
              })}
            </tbody>
          </table>
        </Section>

        {(() => {
          if (
            this.props.state.versionInfoScreen.projectRole === RoleEnum.Adm ||
            this.props.state.versionInfoScreen.projectRole === RoleEnum.Usr
          ) {
            return (
              <ButtonList
                data={[
                  {
                    title: "Gebied bewerken",
                    callback: () => {
                      if (this.props.state.VersionRegionMapScreen.outlineUri) {
                        this.props.stateHandler.changeScreen(
                          ScreenState.MapViewer_VersionRegion,
                          this.props.updateStateCallback
                        );
                      }
                    },
                    disabled: false,
                  },
                  {
                    title: "Functiegebieden bewerken",
                    callback: () => {
                      if (this.props.state.FeatureMapScreen.editorUri) {
                        this.props.stateHandler.changeScreen(
                          ScreenState.MapViewer_Feature,
                          this.props.updateStateCallback
                        );
                      }
                    },
                    disabled: false,
                  },
                  {
                    title: "Bijlage toevoegen",
                    callback: () => {
                      this.props.stateHandler.changeScreen(
                        ScreenState.Projects_VersionModelInfo_NewAttachment,
                        this.props.updateStateCallback
                      );
                    },
                  },
                  {
                    title: "Opslaan",
                    callback: () => {
                      this.props.stateHandler.versionInfoSave(
                        this.props.updateStateCallback
                      );
                    },
                    disabled: this.props.state.versionInfoScreen.loadingForm,
                  },
                  {
                    title: "Annuleren",
                    callback: () => {
                      this.props.stateHandler.changeScreen(
                        ScreenState.Projects,
                        this.props.updateStateCallback
                      );
                    },
                  },
                ]}
              ></ButtonList>
            );
          }
        })()}
      </div>
    );
  }
}
