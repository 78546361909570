import ButtonList from "@shared/components/ButtonList";
import "@shared/components/MessagePopup.css";
import React from "react";
import { MdError } from "react-icons/md";
import GridLoader from "react-spinners/GridLoader";
import ProgressBar from "src/components/ProgressBar";

interface InfoMessagePopupProps {
  message: string;
  progressmessage?: string;
  massageTitle: string;
  progress?: number;
  error?: boolean;
  closeCallback?: () => void;
}

export default class InfoMessagePopup extends React.Component<InfoMessagePopupProps> {
  render() {
    return (
      <div id="MessagePopup">
        <div id="MessagePopup-Section">
          <div id="MessagePopup-Title">{this.props.massageTitle}</div>
          <div id="MessagePopup-Text">{this.props.message}</div>

          {(() => {
            if (this.props.progress !== undefined) {
              return (
                <div id="MessagePopup-ProgressBar">
                  <ProgressBar progress={this.props.progress}></ProgressBar>
                </div>
              );
            }
          })()}

          {(() => {
            if (this.props.progressmessage !== undefined) {
              return (
                <div id="ProgressMessagePopup-Container">
                  {!this.props.error && (
                    <GridLoader size={3} loading={true} color="#14366e" />
                  )}
                  {this.props.error && <MdError size={20} color="#14366e" />}

                  <>{this.props.progressmessage}</>
                </div>
              );
            }
          })()}

          {(() => {
            if (this.props.closeCallback !== undefined) {
              return (
                <div id="MessagePopup-Buttons">
                  <ButtonList
                    data={[
                      {
                        title: "Oké",
                        callback: () => {
                          this.props.closeCallback &&
                            this.props.closeCallback();
                        },
                      },
                    ]}
                  ></ButtonList>
                </div>
              );
            }
          })()}
        </div>
      </div>
    );
  }
}
