import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiPlus, FiSave } from "react-icons/fi";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./CategoryAttachmentScreen.css";

interface CategoryAttachmentScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class CategoryAttachmentScreen extends React.Component<CategoryAttachmentScreenProps> {
  render() {
    return (
      <div id="CategoryAttachmentScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Configuratie",
            },
            {
              title: "Bijlagecategorieën",
            },
          ]}
        ></BreadCrumbs>

        <Section>
          {(() => {
            if (this.props.state.categoryAttachmentScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.categoryAttachmentScreen.formError}
                ></Alert>
              );
            }
          })()}
          <div>
            <table className="CategoryAttachmentScreen-Table">
              <tbody>
                <tr>
                  <th>Omschrijving</th>
                </tr>
                {this.props.state.categoryAttachmentScreen.categoryAttachmentsList.map(
                  (item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <div className="CategoryAttachmentRowContainer">
                            <div className="CategoryAttachmentDescBox">
                              <TextInput
                                type={TextInputType.Text}
                                placeholder={item.name}
                                value={item.newName}
                                onChange={(newvalue) => {
                                  this.props.stateHandler.onCategoryAttachmentEditInputChange(
                                    item.id,
                                    newvalue,
                                    this.props.updateStateCallback
                                  );
                                }}
                              ></TextInput>
                            </div>
                            {item.name !== "Ongecategoriseerd" && (
                              <>
                                <div className="CategoryAttachmentActionBox">
                                  <div
                                    className="CategoryAttachmentScreen-ListItem"
                                    onClick={() => {
                                      this.props.stateHandler.onCategoryAttachmentDeleteClick(
                                        this.props.updateStateCallback,
                                        item.id
                                      );
                                    }}
                                  >
                                    <AiOutlineDelete />
                                  </div>
                                </div>

                                <div
                                  className="CategoryAttachmentActionBox"
                                  style={{
                                    display: item.saveButtonVisible
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <div
                                    className="CategoryAttachmentScreen-ListItem"
                                    onClick={() => {
                                      this.props.stateHandler.onCategoryAttachmentEditClick(
                                        item.id,
                                        item.newName,
                                        this.props.updateStateCallback
                                      );
                                    }}
                                  >
                                    <FiSave />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
                <div className="line"></div>
                <tr>
                  <th>Bijlagecategorie toevoegen</th>
                </tr>
                <tr>
                  <td>
                    <div className="CategoryAttachmentRowContainer">
                      <div className="CategoryAttachmentDescBox">
                        <TextInput
                          value={
                            this.props.state.categoryAttachmentScreen
                              .newCategory
                          }
                          type={TextInputType.Text}
                          placeholder="Bijlagecategorie"
                          onChange={(newvalue) => {
                            this.props.stateHandler.onNewCategoryAttachmentInputChange(
                              newvalue,
                              this.props.updateStateCallback
                            );
                          }}
                        ></TextInput>
                      </div>

                      <div className="CategoryAttachmentActionBox">
                        <div
                          className="CategoryAttachmentScreen-ListItem"
                          onClick={() => {
                            this.props.stateHandler.onCategoryAttachmentAddClick(
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <FiPlus />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Section>
      </div>
    );
  }
}
