/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `RENTEPERCENTAGE_NEGATIEF_SALDO` - RENTEPERCENTAGE_NEGATIEF_SALDO
 * * `RENTEPERCENTAGE_POSITIEF_SALDO` - RENTEPERCENTAGE_POSITIEF_SALDO
 * * `REKENRENTE_CONTANTE_WAARDE_DISCONTERINGSVOET` - REKENRENTE_CONTANTE_WAARDE_DISCONTERINGSVOET
 * * `KOSTENSTIJGING` - KOSTENSTIJGING
 * * `OPBRENGSTENSTIJGING` - OPBRENGSTENSTIJGING
 * * `BTW_PERCENTAGE` - BTW_PERCENTAGE
 * * `STARTDATUM_GRONDEXPLOITATIE_BOEKWAARDE_VANAF` - STARTDATUM_GRONDEXPLOITATIE_BOEKWAARDE_VANAF
 * * `NCW_DATUM` - NCW_DATUM
 * * `PRIJSPEILDATUM` - PRIJSPEILDATUM
 * * `EINDWAARDEDATUM` - EINDWAARDEDATUM
 * * `TOEKOMSTIGE_INDEX_GEBRUIKER` - TOEKOMSTIGE_INDEX_GEBRUIKER
 * * `HISTORISCHE_INDEX_GEBRUIKER` - HISTORISCHE_INDEX_GEBRUIKER
 * @export
 */
export const ParameterListTypeEnum = {
    RentepercentageNegatiefSaldo: 'RENTEPERCENTAGE_NEGATIEF_SALDO',
    RentepercentagePositiefSaldo: 'RENTEPERCENTAGE_POSITIEF_SALDO',
    RekenrenteContanteWaardeDisconteringsvoet: 'REKENRENTE_CONTANTE_WAARDE_DISCONTERINGSVOET',
    Kostenstijging: 'KOSTENSTIJGING',
    Opbrengstenstijging: 'OPBRENGSTENSTIJGING',
    BtwPercentage: 'BTW_PERCENTAGE',
    StartdatumGrondexploitatieBoekwaardeVanaf: 'STARTDATUM_GRONDEXPLOITATIE_BOEKWAARDE_VANAF',
    NcwDatum: 'NCW_DATUM',
    Prijspeildatum: 'PRIJSPEILDATUM',
    Eindwaardedatum: 'EINDWAARDEDATUM',
    ToekomstigeIndexGebruiker: 'TOEKOMSTIGE_INDEX_GEBRUIKER',
    HistorischeIndexGebruiker: 'HISTORISCHE_INDEX_GEBRUIKER'
} as const;
export type ParameterListTypeEnum = typeof ParameterListTypeEnum[keyof typeof ParameterListTypeEnum];


export function ParameterListTypeEnumFromJSON(json: any): ParameterListTypeEnum {
    return ParameterListTypeEnumFromJSONTyped(json, false);
}

export function ParameterListTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterListTypeEnum {
    return json as ParameterListTypeEnum;
}

export function ParameterListTypeEnumToJSON(value?: ParameterListTypeEnum | null): any {
    return value as any;
}

